import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from '../views/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: LoginPage
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/add-employee',
    name: 'add-employee',
    component: () => import(/* webpackChunkName: "add-employee" */ '../views/Pages/Employee/add-employee.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/employee-list',
    name: 'employee-list',
    component: () => import(/* webpackChunkName: "employee-list" */ '../views/Pages/Employee/employee-list.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/employee-type',
    name: 'employee-type',
    component: () => import(/* webpackChunkName: "employee-type" */ '../views/Pages/Employee/employee-type.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/location',
    name: 'location',
    component: () => import(/* webpackChunkName: "location" */ '../views/Pages/Settings/location.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/client-list',
    name: 'client-list',
    component: () => import(/* webpackChunkName: "client-list" */ '../views/Pages/Client/client-list.vue'),
    meta: {requiresAuth: true},
  },
  {
    path: '/client-products/:id',
    name: 'client-products',
    component: () => import(/* webpackChunkName: "client-products" */ '../views/Pages/Client/client-products.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/client-documents/:id',
    name: 'client-documents',
    component: () => import(/* webpackChunkName: "client-documents" */ '../views/Pages/Client/client-documents.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/client-fallowup/:id',
    name: 'client-fallowup',
    component: () => import(/* webpackChunkName: "client-fallowup" */ '../views/Pages/Client/client-fallowup.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/addservice',
    name: 'add-service',
    component: () => import(/* webpackChunkName: "add-service" */ '../views/Pages/Service/add-service.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/servicelist',
    name: 'service-list',
    component: () => import(/* webpackChunkName: "service-list" */ '../views/Pages/Service/service-list.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/blogList',
    name: 'blogList',
    component: () => import(/* webpackChunkName: "blogList" */ '../views/Pages/Blog/blog-list.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/addBlog',
    name: 'addBlog',
    component: () => import(/* webpackChunkName: "addBlog" */ '../views/Pages/Blog/add-blog.vue'),
    meta: {requiresAuth: true},
    props: true
  },
  {
    path: '/blog-category',
    name: 'blog-category',
    component: () => import(/* webpackChunkName: "blog-category" */ '../views/Pages/Blog/blog-category.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/testimonials',
    name: 'testimonials',
    component: () => import(/* webpackChunkName: "testimonials" */ '../views/Pages/Website/website-testimonials.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/website-image-upload',
    name: 'website-image-upload',
    component: () => import(/* webpackChunkName: "website-image-upload" */ '../views/Pages/Website/website-image-upload.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/website-banner',
    name: 'website-banner',
    component: () => import(/* webpackChunkName: "website-banner" */ '../views/Pages/Website/website-banner.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/website-menu',
    name: 'website-menu',
    component: () => import(/* webpackChunkName: "website-menu" */ '../views/Pages/Website/website-menu.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/website-video',
    name: 'website-video',
    component: () => import(/* webpackChunkName: "website-video" */ '../views/Pages/Website/website-video.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/website-partners-logo',
    name: 'website-partners-logo',
    component: () => import(/* webpackChunkName: "website-partners-logo" */ '../views/Pages/Website/website-partners-logo.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/company-info',
    name: 'company-info',
    component: () => import(/* webpackChunkName: "company-info" */ '../views/Pages/Website/company-info.vue'),
    meta: {requiresAuth: true}
  },
  {
    path: '/registration-list',
    name: 'registration-list',
    component: () => import(/* webpackChunkName: "registration-list" */ '../views/Pages/Registration/registration-list.vue'),
    meta: {requiresAuth: true}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)){
    let loginKey = sessionStorage.getItem('loginKey');
    if(!loginKey){
      next({name : 'Login'});
    }else{
      next();
      console.log('loginKey');
    }
  }else{
    let loginKey = sessionStorage.getItem('loginKey');
    if(loginKey) {
      next({name : 'Dashboard'});
    }else {next();}
  }
});

export default router
