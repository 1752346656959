export default {
    employeeList: null,
    employeeType: null,
    employeeTypeList: null,
    locationList: null,
    locationListData: null,
    clientList: null,
    clientProductList: null,
    employeeDropdown: null,
    productDropdown: null,
    subproductDropdown: null,
    servicelist: null,
    bannerList: null,
    partnerList: null,
    videoList: null,
    testimonialList: null,
    newsCategoryList: null,
    newsList: null,
    infoData: null,
    registrationList: null
}