import { API_URL } from '../router/api';
const axios = require('axios');
import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'
Vue.use(VueSweetalert2)

export const getEmployeeList = ({ commit }) => {
    axios.get(`${API_URL}admin_employee_list`)
        .then((response) => {
            let obj = response.data
            commit('SET_EMPLOYEE_LIST', obj);
        })
}
export const getEmployeeDropdown = ({ commit }) => {
    axios.get(`${API_URL}admin_employee_dropdown`)
        .then((response) => {
            let obj = response.data
            commit('SET_EMPLOYEE_DROPDOWN', obj);
        })
}
export const getEmployeeType = ({ commit }) => {
    axios.get(`${API_URL}admin_employee_type`)
        .then((response) => {
            let obj = response.data
            commit('SET_EMPLOYEE_TYPE', obj);
        })
}
export const getEmployeeTypeList = ({ commit }) => {
    axios.get(`${API_URL}employee_type_list`)
        .then((response) => {
            let obj = response.data
            commit('SET_EMPLOYEE_TYPE_LIST', obj);
        })
}
export const getLocationList = ({ commit }) => {
    axios.get(`${API_URL}admin_location`)
        .then((response) => {
            let obj = response.data
            commit('SET_LOCATION_LIST', obj);
        })
}
export const getLocationListData = ({ commit }) => {
    axios.get(`${API_URL}admin_location_list`)
        .then((response) => {
            let obj = response.data
            commit('SET_LOCATION_LIST_DATA', obj);
        })
}
export const getClientList = ({ commit }) => {
    axios.get(`${API_URL}admin_client_list`)
        .then((response) => {
            let obj = response.data
            commit('SET_CLIENT_LIST', obj);
        })
}
export const getClientProductList = ({ commit }) => {
    axios.get(`${API_URL}admin_client_product`)
        .then((response) => {
            let obj = response.data
            commit('SET_CLIENT_PRODUCT_LIST', obj);
        })
}
export const getClientProductDropdown = ({ commit }) => {
    axios.get(`${API_URL}admin_product_dropdown`)
        .then((response) => {
            let obj = response.data
            commit('SET_CLIENT_PRODUCT_DROPDOWN', obj);
        })
}
export const getClientSubproductDropdown = ({ commit }) => {
    axios.get(`${API_URL}admin_subproduct_dropdown`)
        .then((response) => {
            let obj = response.data
            commit('SET_CLIENT_SUBPRODUCT_DROPDOWN', obj);
        })
}
//service api
export const getServiceList = ({ commit }) => {
    axios.post(`${API_URL}admin_service_list`)
        .then((response) => {
            let obj = response.data
            commit('SET_SERVICE_LIST', obj);
        })
}
//Banner show
export const getBannerShow = ({ commit }) => {
    axios.post(`${API_URL}admin_banner_show`)
        .then((response) => {
            let obj = response.data
            commit('SET_BANNER_SHOW', obj);
        })
}

//Partner show
export const getPartnerShow = ({ commit }) => {
    axios.post(`${API_URL}admin_partners_show`)
        .then((response) => {
            let obj = response.data
            commit('SET_PARTNER_SHOW', obj);
        })
}

//Partner show
export const getVideoShow = ({ commit }) => {
    axios.post(`${API_URL}admin_video_show`)
        .then((response) => {
            let obj = response.data
            commit('SET_VIDEO_SHOW', obj);
        })
}

//Partner show
export const getTestimonialShow = ({ commit }) => {
    axios.post(`${API_URL}admin_testimonials_show`)
        .then((response) => {
            let obj = response.data
            commit('SET_TESTIMONIAL_SHOW', obj);
        })
}

//Blog Category show
export const getNewsCategoryShow = ({ commit }) => {
    axios.post(`${API_URL}admin_news_category_show`)
        .then((response) => {
            let obj = response.data
            commit('SET_NEWS_CATEGORY_SHOW', obj);
        })
}
export const getNewsShow = ({ commit }) => {
    axios.post(`${API_URL}admin_news_show`)
        .then((response) => {
            let obj = response.data
            commit('SET_NEWS_SHOW', obj);
        })
}

export const getInfoShow = ({ commit }) => {
    axios.post(`${API_URL}admin_info_show`)
        .then((response) => {
            let obj = response.data
            commit('SET_INFO_SHOW', obj);
        })
}

// Registration
export const getRegistrationList = ({ commit }) => {
    axios.post(`${API_URL}admin_registration_list`)
        .then((response) => {
            let obj = response.data
            console.log(obj)
            commit('SET_REGISTRATION_LIST', obj);
        })
}

// alert Code
export const alertSuccess = ({ commit }, message) => {
    Vue.swal({
        icon: 'success',
        title: `${message}`,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
    })
    // commit(obj);
}
export const alertError = ({ commit }, message) => {
    Vue.swal({
        icon: 'error',
        title: `${message}`,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
    })
    // commit(obj);
}