<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view/>
  </div>
</template>
<script>
// Main Import
export default {
  beforeMount () {
    this.$store.dispatch('getEmployeeList');
    this.$store.dispatch('getEmployeeType');
    this.$store.dispatch('getEmployeeTypeList');
    this.$store.dispatch('getLocationList');
    this.$store.dispatch('getLocationListData');
    this.$store.dispatch('getClientList');
    this.$store.dispatch('getClientProductList');
    this.$store.dispatch('getEmployeeDropdown');
    this.$store.dispatch('getClientProductDropdown');
    this.$store.dispatch('getClientSubproductDropdown');
    this.$store.dispatch('getBannerShow');
    this.$store.dispatch('getPartnerShow');
    this.$store.dispatch('getVideoShow');
    this.$store.dispatch('getNewsCategoryShow');
    this.$store.dispatch('getNewsShow');
    this.$store.dispatch('getTestimonialShow');
    this.$store.dispatch('getInfoShow');
  }
}
</script>
<style lang="scss">
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
