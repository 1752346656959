export const SET_EMPLOYEE_LIST = (state, employeeList) => {
    state.employeeList = employeeList;
};
export const SET_EMPLOYEE_DROPDOWN = (state, employeeDropdown) => {
    state.employeeDropdown = employeeDropdown;
};
export const SET_EMPLOYEE_TYPE = (state, employeeType) => {
    state.employeeType = employeeType;
};
export const SET_EMPLOYEE_TYPE_LIST = (state, employeeTypeList) => {
    state.employeeTypeList = employeeTypeList;
};
export const SET_LOCATION_LIST = (state, locationList) => {
    state.locationList = locationList;
};
export const SET_LOCATION_LIST_DATA = (state, locationListData) => {
    state.locationListData = locationListData;
};
export const SET_CLIENT_LIST = (state, clientList) => {
    state.clientList = clientList;
};
export const SET_CLIENT_PRODUCT_LIST = (state, clientProductList) => {
    state.clientProductList = clientProductList;
};
export const SET_CLIENT_PRODUCT_DROPDOWN = (state, productDropdown) => {
    state.productDropdown = productDropdown;
}; 
export const SET_CLIENT_SUBPRODUCT_DROPDOWN = (state, subproductDropdown) => {
    state.subproductDropdown = subproductDropdown;
};
//service api
export const SET_SERVICE_LIST = (state, servicelist) => {
    state.servicelist = servicelist;
};
export const SET_BANNER_SHOW = (state, bannerList) => {
    state.bannerList = bannerList;
};
export const SET_PARTNER_SHOW = (state, partnerList) => {
    state.partnerList = partnerList;
};
export const SET_VIDEO_SHOW = (state, videoList) => {
    state.videoList = videoList;
};
export const SET_TESTIMONIAL_SHOW = (state, testimonialList) => {
    state.testimonialList = testimonialList
};
export const SET_NEWS_CATEGORY_SHOW = (state, newsCategoryList) => {
    state.newsCategoryList = newsCategoryList
};
export const SET_NEWS_SHOW = (state, newsList) => {
    state.newsList = newsList
};
export const SET_INFO_SHOW = (state, infoData) => {
    state.infoData = infoData
}
export const SET_REGISTRATION_LIST = (state, registrationList) => {
    state.registrationList = registrationList
}