<template>
  <div class="bg-gradient-success loginStyle">
    <!-- Header -->
    <div class="header py-7 py-lg-6 pt-lg-4">
      <b-container>
        <div class="header-body text-center mb-5">
          <b-row class="justify-content-center">
            <b-col xl="5" lg="6" md="8" class="px-2">
              <img width="30%" src="@/assets/logo.png" />
              <h1 class="text-white">Fortunedge Capital CRM</h1>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="5" md="7">
          <b-card no-body class="bg-secondary border-0 mb-0">
            <b-card-body class="px-lg-5 py-lg-5">
              <b-form role="form">
                <base-input
                  alternative
                  class="mb-2"
                  name="Pnone"
                  prepend-icon="ni ni-single-02"
                  placeholder="Phone No"
                  v-model="phone"
                >
                </base-input>
                <base-input
                  alternative
                  class="mb-2"
                  name="Password"
                  prepend-icon="ni ni-lock-circle-open"
                  type="password"
                  placeholder="Password"
                  v-model="password"
                >
                </base-input>
                <div class="text-center">
                  <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                    @hidden="onHidden"
                    style="width: 100%"
                  >
                    <base-button
                      type="success"
                      @click="onSubmit"
                      class=""
                      ref="button"
                      :disabled="busy"
                    >
                      Sign in
                    </base-button>
                  </b-overlay>
                </div>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { API_URL } from "../router/api";
export default {
  name: "Login",
  data() {
    return {
      phone: null,
      password: null,
      busy: false
    };
  },
  methods: {
    onHidden() {
      // Return focus to the button once hidden
      // this.$refs.button.focus()
    },
    onSubmit() {
      this.busy = true;
      this.axios
        .post(`${API_URL}admin_login`, {
          phone: this.phone,
          password: this.password
        })
        .then(response => {
          let status = response.data.status;
          let key = response.data.key;
          let message = response.data.message;
          if (status == "true") {
            sessionStorage.setItem("loginKey", key);
            this.$router.push({ name: "Dashboard" });
          } else {
            this.$store.dispatch("alertError", message);
          }
          this.busy = false;
        })
        .finally(() => {
          this.phone = null;
          this.password = null;
        });
    }
  }
};
</script>
